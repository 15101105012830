@import url("https://fonts.googleapis.com/css?family=Proxima+Nova");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Work+Sans");
@tailwind base;
@tailwind components;
@tailwind utilities;
.toast-notification {
  width: 100% !important;
}
.toast-notification span {
  width: 100% !important;
}

/* @layer components { */
/*  {
  } */
.resourcesContainer__control {
  @apply bg-gray-50 border p-1  cursor-pointer text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-slate-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.resourcesContainer__control--is-focused {
  @apply bg-gray-50 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-slate-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.resourcesContainer__menu {
  @apply bg-gray-50 cursor-pointer p-2 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-slate-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.resourcesContainer__option {
  @apply bg-gray-50 cursor-pointer p-2 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-slate-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
/*  .resourcesContainer__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

.resourcesContainer__indicator-separator {
  @apply bg-neutral-400;
}

.resourcesContainer__input-container,
.resourcesContainer__placeholder,
.resourcesContainer__single-value {
  @apply text-neutral-600 dark:text-neutral-200;
}

.sidebarinner .bg-white {
  @apply !bg-blue-700;
}
.rti--container,
.reactMultiEmail .react-multi-email {
  @apply dark:bg-slate-800 bg-slate-100 h-auto;
}

.reactMultiEmail .react-multi-email input {
  @apply dark:bg-slate-800 dark:text-white bg-slate-100 h-auto;
}
.reactMultiEmail .react-multi-email .data-labels > div {
  @apply dark:bg-slate-800 dark:text-white bg-slate-100 h-auto border border-slate-600;
}
/* } */

.cpli .react-datepicker-wrapper {
  @apply w-full;
}

body {
  font-family: "Inter";
}
.cl-footer {
  display: none;
}

.loader {
  --R: 36px;
  --g1: #222242 96%, #0000;
  --g2: #49494d 96%, #0000;
  width: calc(2 * var(--R));
  height: calc(2 * var(--R));
  border-radius: 50%;
  display: grid;
  -webkit-mask: linear-gradient(#000 0 0);
  animation: spinner-maxc6n 2.4s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  width: 50%;
  background: radial-gradient(farthest-side, var(--g1))
      calc(var(--R) + 0.866 * var(--R) - var(--R))
      calc(var(--R) - 0.5 * var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1))
      calc(var(--R) + 0.866 * var(--R) - var(--R))
      calc(var(--R) - 0.5 * var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g2))
      calc(var(--R) + 0.5 * var(--R) - var(--R))
      calc(var(--R) - 0.866 * var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) 0 calc(-1 * var(--R)),
    radial-gradient(farthest-side, var(--g2))
      calc(var(--R) - 0.5 * var(--R) - var(--R))
      calc(var(--R) - 0.866 * var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1))
      calc(var(--R) - 0.866 * var(--R) - var(--R))
      calc(var(--R) - 0.5 * var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(-1 * var(--R)) 0,
    radial-gradient(farthest-side, var(--g1))
      calc(var(--R) - 0.866 * var(--R) - var(--R))
      calc(var(--R) + 0.5 * var(--R) - var(--R));
  background-size: calc(2 * var(--R)) calc(2 * var(--R));
  background-repeat: no-repeat;
}

.loader::after {
  transform: rotate(180deg);
  transform-origin: right;
}

@keyframes spinner-maxc6n {
  100% {
    transform: rotate(-1turn);
  }
}
.markdown > * {
  all: revert;
}
.prose
  :where(ul ul, ul ol, ol ul, ol
    ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.switchToggle .knobs,
.switchToggle .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switchToggle .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switchToggle .knobs {
  z-index: 2;
}

.switchToggle .layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

.switchToggle.button {
  position: relative;
  top: 50%;
  width: 56px;
  height: 27px;
  /* margin: -20px auto 0 auto; */
  overflow: hidden;
}
.notification.button {
  top: 0;
}

.switchToggle.button.r,
.switchToggle.button.r .layer {
  border-radius: 100px;
}

.switchToggle .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #f12929;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.switchToggle .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

.switchToggle .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

.switchToggle .checkbox:checked + .knobs:before {
  content: "";
  left: 34px;
  background-color: #0e3f2b;
}

.switchToggle .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

.toast-notification {
  z-index: 99999 !important;
}
:root {
  color-scheme: light dark;
}

body,
* {
  scrollbar-width: thin !important;
  scrollbar-color: light-dark(#6f7887, #3b475b) light-dark(#909193, #97989a);
}

[data-testid="flowbite-tooltip"] {
  z-index: 20 !important;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: light-dark(#909193, #97989a);
} */
